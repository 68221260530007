<template lang="pug">
.course-day__transport-item
  transport-icon(:transportType="transport.transport_type").course-day__transport-item-icon
  span.course-day__transport-item-location {{ transport.cbr_location }}
  span {{ transport.exam_start_time | DeleteMS }} - {{ transport.exam_finish_time | DeleteMS}}
</template>

<script>
import { TRANSPORT_TYPE } from '../../course_managment/core/cm-const';

export default {
  name: 'CourseDayTransportInfo',
  props: {
    transport: {
      required: true,
      type: Object
    }
  },

  computed: {
    icon() {
      switch (this.transport.transport_type) {
      case TRANSPORT_TYPE.BUS:
        return 'icoBus'
      case TRANSPORT_TYPE.TAXI:
        return 'icoTaxi'
      case TRANSPORT_TYPE.LOPEND:
        return 'icoLopen'
      default:
        return ''
      }
    }
  },

  components: {
    transportIcon: () => import('@/components/global/TransportIcon.vue'),
    icoBus: () => import('@/assets/img/ui/components/IcoBus.vue'),
    icoTaxi: () => import('@/assets/img/ui/components/IcoTaxi.vue'),
    icoLopen: () => import('@/assets/img/ui/components/IcoLopen.vue')
  }
}
</script>

<style lang="scss">
.course-day {
  &__transport-item {
    display: flex;
    align-items: center;
    margin-right: 2px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: $title-second;
    font-weight: 500;
    font-size: 10px;
    &:first-child {
      padding-top: 0;
    }
    &-location {
      display: inline-block;
      overflow: hidden;
      width: 124px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: $layout-bg;
    }
  }
}

</style>
